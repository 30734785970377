import { lerp, unlerp } from '@kaliber/math'
import { dataLayerEvent } from '/machinery/tracking/dataLayerEvent'

/**
 * @param {{ title:string }} params
 */

export function useVideoTracking({ title }) {
  /** @type {React.MutableRefObject<{ title:string, type: string, duration?: number, progress?: number }>} */
  const videoMetadataRef = React.useRef({ title, type: 'video' })

  const trackEvent = useEvent((event, additionalVideoMetadata = undefined) => {
    dataLayerEvent(
      event,
      {
        interaction: {
          ...videoMetadataRef.current,
          ...additionalVideoMetadata
        }
      }
    )
  })

  return {
    eventHandlers: {
      onStart: useEvent(() => trackEvent('interaction_start')),
      onPause: useEvent(() => trackEvent('interaction_stop')),
      onEnded: useEvent(() => trackEvent('interaction_complete')),
      onSeek: useEvent(seconds => {
        const progress = unlerp({ start: 0, end: videoMetadataRef.current.duration, input: seconds })
        videoMetadataRef.current = { ...videoMetadataRef.current, progress }
        trackEvent('interaction_seek', { progress })
      }),
      onDuration: useEvent(duration => {
        videoMetadataRef.current = { ...videoMetadataRef.current, duration }
      }),
      onProgress: useEvent(({ played }) => {
        const progress = 20 * Math.round(lerp({ start: 0, end: 5, input: played, clamp: true }))
        if (!progress) return

        const progressChanged = progress !== videoMetadataRef.current.progress
        if (!progressChanged) return

        videoMetadataRef.current = { ...videoMetadataRef.current, progress }
        trackEvent('interaction_progress')
      }),
    },
  }
}

function useEvent(fn) {
  const fnRef = React.useRef(null)
  fnRef.current = fn

  return React.useCallback((...args) => fnRef.current(...args), [])
}

