import { useVideoTracking } from '/machinery/tracking/useVideoTracking'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import ReactPlayerBase from 'react-player/vimeo'

import styles from './Video.css'

const playerConfig = {
  playerOptions: {
    controls: true,
    playsinline: false,
    title: false,
    dnt: true,
    responsive: true
  },
}

export function Video({ src, layoutClassName = undefined }) {
  const { eventHandlers } = useVideoTracking({ title: 'Video' })
  const isMounted = useRenderOnMount()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {isMounted && (
        <ReactPlayerBase
          className={styles.player}
          config={playerConfig}
          width='100%'
          height='100%'
          controls
          url={src}
          {...eventHandlers}
        />
      )}
    </div>
  )
}
